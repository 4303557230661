import { ArrowCircleRight } from '@phosphor-icons/react/ArrowCircleRight';
import { ArrowSquareOut } from '@phosphor-icons/react/ArrowSquareOut';
import { BuildingOffice } from '@phosphor-icons/react/BuildingOffice';
import { CaretDown } from '@phosphor-icons/react/CaretDown';
import { CaretLeft } from '@phosphor-icons/react/CaretLeft';
import { CaretRight } from '@phosphor-icons/react/CaretRight';
import { Check } from '@phosphor-icons/react/Check';
import { CheckCircle } from '@phosphor-icons/react/CheckCircle';
import { CircleDashed } from '@phosphor-icons/react/CircleDashed';
import { ClockCountdown } from '@phosphor-icons/react/ClockCountdown';
import { CurrencyCircleDollar } from '@phosphor-icons/react/CurrencyCircleDollar';
import { Dot } from '@phosphor-icons/react/Dot';
import { DotsSix } from '@phosphor-icons/react/DotsSix';
import { DotsThreeOutline } from '@phosphor-icons/react/DotsThreeOutline';
import { DotsThreeVertical } from '@phosphor-icons/react/DotsThreeVertical';
import { Envelope } from '@phosphor-icons/react/Envelope';
import { FileDashed } from '@phosphor-icons/react/FileDashed';
import { Files } from '@phosphor-icons/react/Files';
import { Flag } from '@phosphor-icons/react/Flag';
import { Gear } from '@phosphor-icons/react/Gear';
import { GlobeSimple } from '@phosphor-icons/react/GlobeSimple';
import { Handshake } from '@phosphor-icons/react/Handshake';
import { Heart } from '@phosphor-icons/react/Heart';
import { HourglassMedium } from '@phosphor-icons/react/HourglassMedium';
import { Info } from '@phosphor-icons/react/Info';
import { InstagramLogo } from '@phosphor-icons/react/InstagramLogo';
import { ListPlus } from '@phosphor-icons/react/ListPlus';
import { MagnifyingGlass } from '@phosphor-icons/react/MagnifyingGlass';
import { MegaphoneSimple } from '@phosphor-icons/react/MegaphoneSimple';
import { Pause } from '@phosphor-icons/react/Pause';
import { PencilSimple } from '@phosphor-icons/react/PencilSimple';
import { PlayCircle } from '@phosphor-icons/react/PlayCircle';
import { PlusCircle } from '@phosphor-icons/react/PlusCircle';
import { SealWarning } from '@phosphor-icons/react/SealWarning';
import { SignOut } from '@phosphor-icons/react/SignOut';
import { Smiley } from '@phosphor-icons/react/Smiley';
import { SmileySad } from '@phosphor-icons/react/SmileySad';
import { SquaresFour } from '@phosphor-icons/react/SquaresFour';
import { Star } from '@phosphor-icons/react/Star';
import { TiktokLogo } from '@phosphor-icons/react/TiktokLogo';
import { Timer } from '@phosphor-icons/react/Timer';
import { Translate } from '@phosphor-icons/react/Translate';
import { Trash } from '@phosphor-icons/react/Trash';
import { TrendUp } from '@phosphor-icons/react/TrendUp';
import { User } from '@phosphor-icons/react/User';
import { UserCircle } from '@phosphor-icons/react/UserCircle';
import { UserFocus } from '@phosphor-icons/react/UserFocus';
import { UserPlus } from '@phosphor-icons/react/UserPlus';
import { Users } from '@phosphor-icons/react/Users';
import { Warning } from '@phosphor-icons/react/Warning';
import { X } from '@phosphor-icons/react/X';
import { XCircle } from '@phosphor-icons/react/XCircle';
import { YoutubeLogo } from '@phosphor-icons/react/YoutubeLogo';
import { twMerge } from 'tailwind-merge';

import spriteHref from '../components/icons/icons.svg';
import { captureException } from '../errors/tracking/utils';

import { iconNames, type IconName } from './icons/types';

// Please, sort icon names by a-z order when adding new ones

// cf. https://fonts.google.com/icons
const materialIcons = {
  add: 'add',
  arrow_drop_down: 'arrow_drop_down',
  keyboard_arrow_down: 'keyboard_arrow_down',
  keyboard_arrow_right: 'keyboard_arrow_right',
  assignment: 'assignment',
  campaign: 'adjust',
  client: 'person',
  close: 'close',
  collaboration: 'work',
  collect_data: 'inventory',
  content_copy: 'content_copy',
  creators_accounting: 'payments',
  download: 'download',
  edit: 'edit',
  hidden: 'visibility_off',
  included: 'task_alt',
  live_tv: 'live_tv',
  next: 'arrow_forward_ios',
  open_in_new: 'open_in_new',
  organization: 'corporate_fare',
  pdf: 'picture_as_pdf',
  pending_tasks: 'fact_check',
  people: 'people',
  person: 'person',
  plan_and_billing: 'credit_card',
  previous: 'arrow_back_ios',
  schedule: 'schedule',
  settings_input_antenna: 'settings_input_antenna',
  ship_physical_asset: 'local_shipping',
  show_chart: 'show_chart',
  signup: 'post_add',
  unfold: 'unfold_more',
  workspace: 'grid_view',
} as const;

type MaterialIcon = keyof typeof materialIcons;

// cf. https://phosphoricons.com/
const phosphorIcons = {
  arrow_right_filled_circle: { component: ArrowCircleRight, variant: 'fill' },
  arrow_square_out: { component: ArrowSquareOut, variant: 'regular' },
  building_office: { component: BuildingOffice, variant: 'bold' },
  caret_down: { component: CaretDown, variant: 'bold' },
  caret_left: { component: CaretLeft, variant: 'bold' },
  circle_dashed: { component: CircleDashed, variant: 'bold' },
  collaboration_flagged: { component: Flag, variant: 'fill' },
  collaboration_unflagged: { component: Flag, variant: 'regular' },
  flagged: { component: Flag, variant: 'fill' },
  unflagged: { component: Flag, variant: 'bold' },
  creator: { component: UserFocus, variant: 'regular' },
  caret_right: { component: CaretRight, variant: 'bold' },
  check: { component: Check, variant: 'bold' },
  clock_countdown: { component: ClockCountdown, variant: 'bold' },
  currency_circle_dollar: { component: CurrencyCircleDollar, variant: 'bold' },
  dot: { component: Dot, variant: 'fill' },
  dots_three_vertical: { component: DotsThreeVertical, variant: 'bold' },
  dots_three_horizontal: { component: DotsThreeOutline, variant: 'fill' },
  draft_pending_validation: { component: HourglassMedium, variant: 'regular' },
  draft: { component: FileDashed, variant: 'fill' },
  drag: { component: DotsSix, variant: 'regular' },
  envelope: { component: Envelope, variant: 'regular' },
  files: { component: Files, variant: 'bold' },
  globe_simple: { component: GlobeSimple, variant: 'bold' },
  heart: { component: Heart, variant: 'fill' },
  handshake: { component: Handshake, variant: 'bold' },
  instagram: { component: InstagramLogo, variant: 'regular' },
  instagram_bold: { component: InstagramLogo, variant: 'bold' },
  info: { component: Info, variant: 'regular' },
  list_plus: { component: ListPlus, variant: 'regular' },
  list_plus_bold: { component: ListPlus, variant: 'bold' },
  megaphone_simple: { component: MegaphoneSimple, variant: 'bold' },
  magnifying_glass: { component: MagnifyingGlass, variant: 'bold' },
  play_circle: { component: PlayCircle, variant: 'regular' },
  play_circle_bold: { component: PlayCircle, variant: 'bold' },
  plus_circle: { component: PlusCircle, variant: 'regular' },
  pause: { component: Pause, variant: 'fill' },
  plus_circle_bold: { component: PlusCircle, variant: 'bold' },
  pencil_simple: { component: PencilSimple, variant: 'bold' },
  reject: { component: XCircle, variant: 'regular' },
  seal_warning: { component: SealWarning, variant: 'fill' },
  settings: { component: Gear, variant: 'regular' },
  star_regular: { component: Star, variant: 'regular' },
  square_four: { component: SquaresFour, variant: 'bold' },
  star: { component: Star, variant: 'fill' },
  sign_out: { component: SignOut, variant: 'bold' },
  smiley: { component: Smiley, variant: 'bold' },
  smiley_sad: { component: SmileySad, variant: 'bold' },
  tiktok: { component: TiktokLogo, variant: 'regular' },
  tiktok_bold: { component: TiktokLogo, variant: 'bold' },
  timer: { component: Timer, variant: 'regular' },
  translate: { component: Translate, variant: 'fill' },
  trash: { component: Trash, variant: 'regular' },
  trend_up: { component: TrendUp, variant: 'regular' },
  users: { component: Users, variant: 'bold' },
  user: { component: User, variant: 'bold' },
  user_plus: { component: UserPlus, variant: 'bold' },
  user_circle: { component: UserCircle, variant: 'bold' },
  validate: { component: CheckCircle, variant: 'regular' },
  warning: { component: Warning, variant: 'fill' },
  X: { component: X, variant: 'bold' },
  youtube: { component: YoutubeLogo, variant: 'regular' },
  youtube_bold: { component: YoutubeLogo, variant: 'bold' },
} as const;

export type PhosphorIcon = keyof typeof phosphorIcons;

export type IconProps = React.ComponentPropsWithoutRef<'i'> & {
  name: MaterialIcon | PhosphorIcon | IconName;
  className?: string;
};

/** See https://fonts.google.com/icons */
export const Icon = ({ name, className, ...rest }: IconProps) => {
  return (
    <i className={twMerge('material-icons text-[1.25em] align-middle', className)} aria-hidden="true" {...rest}>
      {getIcon(name)}
    </i>
  );
};

function getIcon(name: MaterialIcon | PhosphorIcon | IconName): React.ReactNode {
  const materialIcon = materialIcons[name as MaterialIcon];

  if (materialIcon) {
    return materialIcon;
  }

  const phosphorIcon = phosphorIcons[name as PhosphorIcon];

  if (phosphorIcon) {
    const PhosphorIconComponent = phosphorIcon.component;
    // Use em to scale both icon sets with font-size
    return <PhosphorIconComponent size="1em" weight={phosphorIcon.variant} />;
  }

  if (iconNames.includes(name as IconName)) {
    return (
      <svg fill="currentColor" width={'1em'} height={'1em'}>
        <use href={`${spriteHref}#${name}`} />
      </svg>
    );
  }

  captureException(new Error('unknown_icon'), { extra: { icon: name } });

  // Default icon to avoid breaking the app
  return 'question_mark';
}
